import React from 'react'
import Navbar2 from './Navbar2'
import '../styles/Policy.css'
import Footer from './Footer';
const Policy = () => {

  const services2 = [
    { title: 'Information We Gather:', text: "Pixalogic (the “Company”) is the rightful owner of every information gathered on Pixalogic (URL). The information collected is not shared, sold or rented to any third-party resource except as mentioned in this declaration.  \nWe gather personal information and data when you register an account on Pixalogic. We use the gathered information to provide you with personalized services and to improve the quality of our services. The customers are required to provide us with their user name, email address, phone numbers and delivery address. To make sure that your personal details and information remains protected and confidential, you are requested not to share this information with anyone." },
    { title: 'Use of Personal Data And Information:', text: "Pixalogic uses the provided personal details and information in accordance with the privacy policy and similar service-specific declaration. If you decide to provide the personal details for a particular purpose, we make sure that the information is used only to fulfill that purpose only. For instance, if you have filled in your personal information to acquire accessibility to the site, the provided information will be used to administer accessibility. The company and its affiliates also use this information to improve the content, functionality of the site. Additionally, this information is also used to gain a better understanding of our users and their requirements and to improve our services accordingly." },
    { title: 'Use Of Cookies:', text: "To provide our customers with relevant experience, we use cookies to gather the relevant information. Cookies is a comprehensive piece of information that is sent to the computer accessing our site through the host computer. The cookies help us in providing additional functionality to the website. It also assists us in a thorough and accurate analysis of website usability." },
    { title: 'Security:', text: "We take every possible measure to ensure that the personal information and data is well-safeguarded. Our initiatives and measures ensure avoidance of information loss, information misuse, unauthorized access, disclosure and/or alteration of information. However, information sent through digital and online mediums is never truly secured and error-free. The information and emails sent to and from this website may or may not be fully secured. In order to avoid any security risk, be careful with the information you send to us through email." },
    { title: 'Use Of Site By Children: ', text: "Pixalogic is not intended for use by children or individuals under the age of 18. The company does not sell or offer services for children. The services listed on this website are meant for individuals over the age of 18. In case underage individual wishes to use the website, parental guidance is highly suggested. We will not gather any information or personal details from the children under the age of 18, knowingly or willingly. At Pixalogic, we are committed to safeguarding your privacy, welfare and information discretion." },
    { title: 'Access To Information:', text: "To make sure that the provided data and information is accurate, updated and comprehensive, contact us on the links provided below. We will make sure to update and change the details provided previously via our website." },
    { title: 'Opt-out Policy:', text: "If, after registering, you wish to change the information and updates we send to you through notification and emails, you can send us a request through mail to specify your new and current preferences." },
    { title: 'Third-party Links:', text: "This declaration is only for Pixalogic (URL) and is not applicable to any external resource or third-party websites. Pixalogic doesn’t control or monitor the activities of third-party links and is not responsible for their operations and functionalities." },
    { title: 'Change To The Privacy Policy:', text: "The company reserves all the rights to update, change or re-draft the privacy policy at any time without any prior notice. We might update and change the conditions of this declaration from time to time; therefore, you are requested to visit this declaration to review our updated privacy policy, especially before submitting any personal details. Your continued use of this website shall indicate your agreement with the revised terms of our policy. However, if you don’t agree with our policy, you are requested not to access, use or download any content or material from this website." },

  ];



  return (
    <div className=''>
      <Navbar2 />
      <div className='container-policy-pg'>
        <h1>Privacy Policy</h1>

        {services2.map((service, index) => (
          <div className="policy-pg-map" key={index}>

              <h1>{service.title}</h1>
              <p>{service.text}</p>
          </div>
        ))}

      </div>
      <Footer />
    </div>
  )
}

export default Policy
