import React, { useState, useEffect, useRef } from 'react';
import '../styles/Counter.css' 

const Counter = ({ targetNumber, duration, prefix = '', suffix = '' , }) => {
  const [count, setCount] = useState(0);
  const counterRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 } // Adjust this threshold as needed
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      let start = 0;
      const end = targetNumber;
      if (start === end) return;

      let totalMilSecDur = parseInt(duration) * 1000; // Convert to milliseconds
      let incrementTime = totalMilSecDur / end;

      let timer = setInterval(() => {
        start += 1;
        setCount(start);
        if (start === end) clearInterval(timer);
      }, incrementTime);
    }
  }, [isVisible, targetNumber, duration]);

  return (
    <div ref={counterRef} className='counter'>
    <h1>{`${prefix}${count}${suffix}`}</h1>
  </div>

  );
};

export default Counter;
