import React, { useEffect } from 'react'
import Navbar from './Navbar'
import '../styles/Home.css'
import { useNavigate } from 'react-router-dom';


const Home = () => {
  const navigate = useNavigate();

  const handleServiceClick = () => {
    navigate('/contact');
  };

  useEffect(() => {
    // Function to scroll to the top of the page
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    // Add event listener to scroll to top when the component unmounts
    return () => {
      scrollToTop();
    };
  }, []); // Run only once when the component mounts


  return (
    <>
      <Navbar />

      <div className='container'>

        <div className='content'>
          <div className='text'>
            <h1>
              <span className="first-line">Your Perfect</span>
              <span className="second-line">Branding Partner</span>
            </h1>
            <div className='para-cont'>
            <p className='p1'>Enhance your business with our innovative strategies that drive success and < br />
          growth. Unlock your potential today with our unique approach to marketing.</p>
            </div>
            <button onClick={handleServiceClick} className='get-started'>GET STARTED</button>
          </div>

          <div className='grp'>
            <img src={require('../assets/grp.png')} alt='branding' className='group' />
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
