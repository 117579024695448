import React from 'react'
import Navbar2 from './Navbar2'
import '../styles/Terms.css'
import Footer from './Footer';



const Terms = () => {

  const services2 = [
    { title: 'Revision Policy', text: "You can get as many revisions as stated in the elected package details. We strive to deliver excellence and customer satisfaction, and we will revise your design until every requirement is met. Once the design is finalized, the requested revision will be considered as a new order and will be charged separately." },
    { title: 'Quality Assurance Policy:', text: "Our designers and creatives go the extra mile to meet every design requirement and provide you with high-quality designs. Hours of research and efforts go into the creation of every design. We guarantee exclusivity and the highest grade of design quality." },
    { title: '100% satisfaction guarantee:', text: "Our satisfied customers and clients are a source of motivation and inspiration for us:- Our policy of countless design revisions is an assurance for 100% satisfaction.- Our chief objective is to fulfill your expectations, and we go out of the way to achieve that.- We recraft the ordered design until you are completely satisfied (number of revisions depends on the package)."},
    { title: 'Delivery Policy:', text: `•  Once the order is completed, the finalized design will be sent to the provided account on the date mentioned in the order confirmation. To ensure successful delivery, a   \nconfirmation mail will also be sent to the email address.
•  The turnaround time depends on the package; the minimum time is two business days.
•  Contact our customer service and support team in case of any urgent design requirement.` },
    { title: 'Record maintenance:', text: "Pixalogic maintains a record of every finalized and delivered design that we can provide you with the exact file in case of any order misplacement." },
    { title: 'Customer support:', text: "Our customer support team is available to resolve all your queries and issues; our support team will respond to all your questions and concerns." },
    { title: 'Communication Policy:', text: "If, after registering, you wish to change the information and updates we send to you through notification and emails, you can send us a request through mail to specify your new and current preferences." },
    { title: 'Eligibility for refunds:', text: "Pixalogic manages and professionally handles every project and ensures that the clients get the finalize design as per the conditions mentioned in this declaration. In case the customer and client are not satisfied with the provided design and has gone through dispute resolution procedures and dealings, we do consider and process refund request as per our policy associated with service." },
    { title: 'Processing Of Refunds:', text: "Refunds will only be processed and reimbursed in business days and within a few days of the cancellation date. The amount will be refunded through a similar payment method that was used for original payments." },
    { title: 'Coverage and Scope:' , text: "Our refund policy covers the handling of refunds processed by Pixalogic and /or the website run and owned by Pixalogic. Our refund policy does not apply to the practices of other companies that are not controlled or operated by Pixalogic, or to the individuals not employed or managed by Pixalogic which also includes the third-party websites and services." },

  ];



  return (
    <div className=''>
      <Navbar2 />
      <div className='container-term-pg'>
        <h1>TERMS OF USE</h1>
       
          <div className="term-pg-map">
            <h1>Revision Policy</h1>
            <p>You can get as many revisions as stated in the elected package details. We strive to deliver excellence and customer satisfaction, and we will revise your design until every requirement is met. Once the design is finalized, the requested revision will be considered as a new order and will be charged separately.</p>

            <h1>Quality Assurance Policy:</h1>
            <p>Our designers and creatives go the extra mile to meet every design requirement and provide you with high-quality designs. Hours of research and efforts go into the creation of every design. We guarantee exclusivity and the highest grade of design quality.</p>

            <h1>100% satisfaction guarantee:</h1>
            <p>Our satisfied customers and clients are a source of motivation and inspiration for us:
                <li>
                Our policy of countless design revisions is an assurance for 100% satisfaction.
                </li>
                <li>
                Our chief objective is to fulfill your expectations, and we go out of the way to achieve that.
                </li>
                <li>
                We recraft the ordered design until you are completely satisfied (number of revisions depends on the package).
                </li>
            </p>

            <h1>Delivery Policy:</h1>
            <ul>
                <li>
                Once the order is completed, the finalized design will be sent to the provided account on the date mentioned in the order confirmation. To ensure successful delivery, a confirmation mail will also be sent to the email address.
                </li>
                <li>
                The turnaround time depends on the package; the minimum time is two business days.
                </li>
                <li>
                Contact our customer service and support team in case of any urgent design requirement.
                </li>
            </ul>

            <h1>Record maintenance:</h1>
            <p>Pixalogic maintains a record of every finalized and delivered design that we can provide you with the exact file in case of any order misplacement.</p>

            <h1>Customer support:</h1>
            <p>Our customer support team is available to resolve all your queries and issues; our support team will respond to all your questions and concerns.</p>

            <h1>Communication Policy:</h1>
            <ul>
                <li>Make sure to contact us only on your official website (URL) and on numbers listed on our platform.</li>
                <li>We will not be responsible for any mishap or loss caused by contacts that we don’t own.</li>
                <li>We are fully responsible for the information given through our official platform and domains.</li>
            </ul>

            <h1>Eligibility for refunds:</h1>
            <p>Pixalogic manages and professionally handles every project and ensures that the clients get the finalize design as per the conditions mentioned in this declaration. In case the customer and client are not satisfied with the provided design and has gone through dispute resolution procedures and dealings, we do consider and process refund request as per our policy associated with service.</p>

            <h1>Processing of refunds: </h1>
            <p>Refunds will only be processed and reimbursed in business days and within a few days of the cancellation date. The amount will be refunded through a similar payment method that was used for original payments.</p>

            <h1>Coverage and Scope:</h1>
            <p>Our refund policy covers the handling of refunds processed by Pixalogic and /or the website run and owned by Pixalogic. Our refund policy does not apply to the practices of other companies that are not controlled or operated by Pixalogic, or to the individuals not employed or managed by Pixalogic which also includes the third-party websites and services.</p>
          </div>
    
      </div>
      <Footer />
    </div>
  )
}

export default Terms
