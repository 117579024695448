import React, { useState, useEffect } from 'react';
import logo from '../assets/logo.png';
import '../styles/Navbar.css';
import Contact2 from './Contact2';

const Navbar = () => {
    const [isContactFormVisible, setContactFormVisible] = useState(false);
    const [isNavOpen, setNavOpen] = useState(false);

    useEffect(() => {
        const disableScroll = () => {
            document.body.style.overflow = 'hidden';
        };

        const enableScroll = () => {
            document.body.style.overflow = '';
        };

        if (isContactFormVisible || isNavOpen) {
            disableScroll();
        } else {
            enableScroll();
        }

        return () => enableScroll();
    }, [isContactFormVisible, isNavOpen]);

    const handleHireUsClick = () => {
        setContactFormVisible(true);
    };

    const handleCloseForm = () => {
        setContactFormVisible(false);
    };

    const toggleNav = () => {
        const body = document.body;
        setNavOpen(!isNavOpen);
        if (!isNavOpen) {
            body.style.overflow = 'hidden';
        } else {
            body.style.overflow = '';
        }
    };
    
    return (
        <>
            <nav className={isContactFormVisible ? 'disabled' : ''}>
                <a href="#" className="logo">
                    <img src={logo} alt="Logo" />
                </a>
                <button className={`hamburger ${isNavOpen ? 'open' : ''}`} onClick={toggleNav}>
                    <div></div>
                    <div></div>
                    <div></div>
                </button>
                <div className={`links ${isNavOpen ? 'open' : ''}`}>
                    <a href="/" onClick={toggleNav}>Home</a>
                    <a href="/about" onClick={toggleNav}>About</a>
                    <a href="/services" onClick={toggleNav}>Services</a>
                    <a href="/work" onClick={toggleNav}>Work</a>
                    <a href="/contact" onClick={toggleNav}>Contact</a>
                    <button onClick={handleHireUsClick} className="hire-us">HIRE US</button>
                </div>
            </nav>
            {isContactFormVisible && (
                <>
                    <div className="overlay" onClick={handleCloseForm}></div>
                    <Contact2 onClose={handleCloseForm} />
                </>
            )}
            {isNavOpen && <div className="blur-background"></div>}
        </>
    );
};

export default Navbar;
