import React, { useEffect, useRef } from 'react'
import '../styles/Contact2.css'
import { useForm, ValidationError } from '@formspree/react';

const Contact2 = ({ onClose }) => {
  const [state, handleSubmit] = useForm("xoqgzpyr");
  const handleFormSubmit = async (e) => {
    await handleSubmit(e); // Submit the form
    if (state.succeeded) {
      // Reset the form fields after successful submission
      e.target.reset();
    }
  };

  useEffect(() => {
    if (state.succeeded) {
      alert('Form submitted successfully!');
    }
  }, [state.succeeded]);


  return (
    <div className='contact-container2'>

<span className="close-button2" onClick={onClose}>&times;</span>


      <div className='contact-right2'>

      <h1> <span className='lets-c1'>Let's collaborate <br />
        to achieve
      </span> <span className='great1'> great </span>  <br />
        <span className='great1'> results.  </span> </h1>


        <form className='form2' onSubmit={handleFormSubmit}>


          {/* <label htmlFor="email">
            First Name
          </label> */}
            <input
              id="message"
              name="received from"
              placeholder='Your Name'
              className='f-name2'
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />

            {/* <input
              id="message"
              placeholder='Last Name'
              className='l-name2'

            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            /> */}


            <input
              id="message"
              name="contact no."
              placeholder='Phone Number'
              className='phone2'
            />

            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />


            <input
              id="email"
              type="email"
              name="email"
              placeholder='Email'
              className='email2'

            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />

          <input
            id="message"
            name="Address"
            placeholder='Address'
            className='address2'
          />


          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />


          <textarea rows={7}
            id="message"
            name="About Project"
            placeholder='Project Description'
            className='proj-desc2'
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
          <button className="c2-submit" type="submit" disabled={state.submitting}>
            SUBMIT
          </button>
        </form>


      </div>


    </div>
  )
}

export default Contact2
