import React, { useState } from 'react';
import logo from '../assets/logo.png';
import { HiOutlineBars3 } from "react-icons/hi2";
import '../styles/Navbar.css';
import Contact2 from './Contact2';
import { Link } from 'react-router-dom';

const Navbar2 = () => {
    const [isContactFormVisible, setContactFormVisible] = useState(false);
    const [originalOverflow, setOriginalOverflow] = useState('');
    const [isNavOpen, setNavOpen] = useState(false);

    const scrollToSection = (sectionId, offset) => {
        const section = document.getElementById(sectionId);
        const navbarHeight = document.querySelector('nav').offsetHeight;
        if (section) {
            window.scrollTo({
                top: section.offsetTop - navbarHeight - offset,
                behavior: "smooth"
            });
        }
    };

    const handleHireUsClick = () => {
        setOriginalOverflow(document.body.style.overflow); // Save the current overflow settings
        setContactFormVisible(true);
        document.body.style.overflow = 'hidden'; // Disable scrolling
    };

    const handleCloseForm = () => {
        setContactFormVisible(false);
        document.body.style.overflow = originalOverflow; // Restore original overflow settings
    };
    const toggleNav = () => {
        const body = document.body;
        setNavOpen(!isNavOpen);
        if (!isNavOpen) {
            body.style.overflow = 'hidden';
        } else {
            body.style.overflow = '';
        }
    };

    return (
        <>
            <nav className={` ${isContactFormVisible ? 'disabled' : ''}`}>
                <a href="/" className="logo">
                    <img src={logo} alt="Logo" />
                </a>
                <button className={`hamburger ${isNavOpen ? 'open' : ''}`} onClick={toggleNav}>
                    <div></div>
                    <div></div>
                    <div></div>
                </button>
                <div className={`links ${isNavOpen ? 'open' : ''}`}>
                    <a href="/" onClick={() => scrollToSection("home-section", 0)}>Home</a>
                    <Link to="/about" onClick={() => scrollToSection("about-section", 140)}>About</Link>
                    <a href="/services" onClick={() => scrollToSection("service-section", 400)}>Services</a>
                    <a href="/work" onClick={() => scrollToSection("work-section", 730)}>Work</a>
                    <a href="/contact" onClick={() => scrollToSection("contact-section", 670)}>Contact</a>
                    <button onClick={handleHireUsClick} className="hire-us">HIRE US</button>
                </div>
            </nav>
            {isContactFormVisible && (
                <>
                    <div className="overlay" onClick={handleCloseForm}></div>
                    <Contact2 onClose={handleCloseForm} />
                </>
            )}                   {isNavOpen && <div className="blur-background"></div>}

        </>
    );
};

export default Navbar2;
