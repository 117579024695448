import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useSpring, animated } from '@react-spring/web';
import '../styles/Testinomials.css';

const Testinomials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [triggerBounce, setTriggerBounce] = useState(false);
  const services = [
    { img: require('../assets/test-img.png'), title: 'Malik Zeeshan', title2: 'AutoDen-CEO', text: "A sophisticated designing platform with extensive customizability and quicker configurability. Packed with tens of potentials," },
    { img: require('../assets/test-img.png'), title: 'Abid Rehman', title2: 'Gehna Center-Owner', text: "Really professionals team work & the best logo designers and other works by them i just made my firm logo I am so happy they made." },

    { img: require('../assets/test-img.png'), title: 'Malik Zeeshan', title2: 'AutoDen Essentials – CEO', text: "Choosing Pixalogic Solutions was the best decision for our new venture. They perfectly captured our vision, creating a standout brand identity. Their creativity and attention to detail are exceptional." },
    { img: require('../assets/test-img.png'), title: 'Zaid Hasany', title2: 'Bench & Oven – Owner', text: "Choosing Pixalogic Solutions was the best decision for our new venture. They perfectly captured our vision, creating a standout brand identity. Their creativity and attention to detail are exceptional." },
    { img: require('../assets/test-img.png'), title: 'Yasir Hashmi', title2: 'Vortox Animations - Founder', text: "We are thrilled with the logo animation Pixalogic created. It perfectly captures our brand with dynamic transitions and a cohesive color scheme, making it memorable and visually appealing." },
    { img: require('../assets/test-img.png'), title: 'Zeeshan Hanif', title2: 'Fobster – Marketing Director', text: "Thrilled with Pixalogic Solutions' logo and social media work! They perfectly captured our brand and boosted our online presence. Outstanding professionalism and creativity. Highly recommend!" },

  ];

  const nextSlide = () => {
    if (currentIndex < services.length - 2) {
      setCurrentIndex(currentIndex + 2); // Move by two images
      setTriggerBounce(true);
    }
  };

  const prevSlide = () => {
    if (currentIndex >= 2) {
      setCurrentIndex(currentIndex - 2); // Move by two images
      setTriggerBounce(true);
    }
  };

  useEffect(() => {
    if (triggerBounce) {
      const timer = setTimeout(() => setTriggerBounce(false), 300); // duration of the bounce animation
      return () => clearTimeout(timer);
    }
  }, [triggerBounce]);

  return (
    <div className="test-container">
      <div className="test-wrap">
        <h1>Our Testimonial</h1>
        <div className="tarrow-container">
          <FontAwesomeIcon icon={faArrowLeft} className="tarrow-icon-left" onClick={prevSlide} size='2x' />
          <FontAwesomeIcon icon={faArrowRight} className="tarrow-icon-right" onClick={nextSlide} size='2x' />
        </div>
      </div>
      <div className="thorizontal-container" style={{ transform: `translateX(-${currentIndex * 50}%)` }}>
        {services.map((service, index) => (
          <div className="test1" key={index}>
            <TestimonialItem service={service} triggerBounce={triggerBounce} />
          </div>
        ))}
      </div>
    </div>
  );
};

const TestimonialItem = ({ service, triggerBounce }) => {
  const bounceRef = useRef();

  const bounceSpring = useSpring({
    from: { transform: 'scale(1)' },
    to: { transform: triggerBounce ? 'scale(1.2)' : 'scale(1)' },
    config: { tension: 600, friction: 10 },
  });

  useEffect(() => {
    if (triggerBounce) {
      bounceRef.current = setTimeout(() => {
        bounceRef.current = null;
      }, 300); // duration of the bounce animation
    }
  }, [triggerBounce]);

  return (
    <>
      <animated.div className="timg-con" style={bounceSpring}>
        <img src={service.img} alt={service.title} />
      </animated.div>
      <div className="test-content">
        <p className='service-p'>{service.text}</p>
        <div className='title-area'>
          <h1 className='title1'>
            {service.title}<br />
            <span className='title2'>{service.title2}</span>
          </h1>
        </div>
      </div>
    </>
  );
};

export default Testinomials;
