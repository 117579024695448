import React, { useEffect, useRef } from 'react'
import '../styles/Contact.css'
import { useForm, ValidationError } from '@formspree/react';

const Contact = () => {


  const [state, handleSubmit] = useForm("xoqgzpyr");
  const handleFormSubmit = async (e) => {

    await handleSubmit(e); // Submit the form
    if (state.succeeded) {
      // Reset the form fields after successful submission
      e.target.reset();
    }
  };


  useEffect(() => {
    if (state.succeeded) {
      alert('Form submitted successfully!');
    }
  }, [state.succeeded]);


  return (
    <div className='contact-container'>


      <h1> <span className='lets-c'>Let's collaborate <br />
        to achieve
      </span> <span className='great'> great </span>  <br />
        <span className='great'> results.  </span> </h1>


      <div className='contact-right'>

        <h2>
          Get A Quote
        </h2>
        <form onSubmit={handleFormSubmit}>


          {/* <label htmlFor="email">
            First Name
          </label> */}
          <div className='contact-name'>
            <input
              id="firstName"
              name="received from"
              placeholder='First Name'

              className='f-name'
            />
            <ValidationError
              prefix="Message"
              field="firstName"
              errors={state.errors}
            />

            <input
              id="firstName"
              placeholder='Last Name'
              className='l-name'
              name='Last Name'
            />
            <ValidationError
              prefix="Message"
              field="lastName"
              errors={state.errors}
            />

          </div>

          <div className='phone-email'>

            <input
              id="message"
              name="contact no."
              placeholder='Phone Number'
              className='phone'
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />


            <input
              id="email"
              type="email"
              name="email"
              placeholder='Email'
              className='email'
            />

            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />

          </div>

          <input
            id="message"
            name="Address"
            placeholder='Address'
            className='address'
          />


          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />


          <textarea rows={7}
            id="message"
            name="About Project"
            placeholder='Project Description'
            className='proj-desc'
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
          <button type="submit" disabled={state.submitting}>
            SUBMIT
          </button>
        </form>


      </div>


    </div>
  )
}

export default Contact
