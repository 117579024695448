import React, { useEffect, useRef } from 'react'
import '../styles/ContactPg.css'
import { useForm, ValidationError } from '@formspree/react';
import Navbar2 from './Navbar2';
import Footer from './Footer';



const ContactPg = () => {
    const [state, handleSubmit] = useForm("xoqgzpyr");
    const handleFormSubmit = async (e) => {
        await handleSubmit(e); // Submit the form
        if (state.succeeded) {
            // Reset the form fields after successful submission
            e.target.reset();
        }
    };

    useEffect(() => {
        if (state.succeeded) {
          alert('Form submitted successfully!');
        }
      }, [state.succeeded]);
    return (

        <>
            <Navbar2 />
            <div className='contact-pg-h'>

            <h1> <span className='lets-c-pg'>Let's collaborate <br />
        to achieve
      </span> <span className='great-pg'> great results</span>  <br />
        <span className='great-pg'>  </span> </h1>            </div>
            <div className='ContactPg-container'>

                <div className='contact-pg-rh'>


                    <h1>Don’t be afraid to say<br />
                        hello with us!
                        <br />
                    </h1>

                    <h1>
                        Phone
                    </h1>

                    <p>
                    +92 336 3361132
                    </p>


                    <h1>
                        Email
                    </h1>

                    <p>
                    info@pixalogicsoultions.com 
                                        </p>

                    <h1>
                        Address
                    </h1>

                    <p>
                    Office No #104, Sahba Akhtar Rd, <br />
                    Block 13D-2 Gulshan-e-Iqbal,<br />
                    Karachi,

                    </p>
                </div>

                <div className='ContactPg-right'>

                    <h2>
                        Get A Quote
                    </h2>
                    <form className='contact-pg-form' onSubmit={handleFormSubmit}>


                        {/* <label htmlFor="email">
                 First Name
                </label> */}
                        <div className='ContactPg-name'>
                            <input
                                id="message"
                                name="received from"
                                placeholder='First Name'

                                className='f-name-pg'
                            />
                            <ValidationError
                                prefix="Message"
                                field="message"
                                errors={state.errors}
                            />

                            <input
                                id="message"
                                placeholder='Last Name'
                                className='l-name-pg'

                            />
                            <ValidationError
                                prefix="Message"
                                field="message"
                                errors={state.errors}
                            />

                        </div>

                        <div className='phone-email-pg'>

                            <input
                                id="message"
                                name="ContactPg no."
                                placeholder='Phone No.'
                                className='phone-pg'
                            />
                            <ValidationError
                                prefix="Message"
                                field="message"
                                errors={state.errors}
                            />





                            <input
                                id="email"
                                type="email"
                                name="email"
                                placeholder='Email Address'
                                className='email-pg'

                            />
                            <ValidationError
                                prefix="Email"
                                field="email"
                                errors={state.errors}
                            />

                        </div>

                        <input
                            id="message"
                            name="Address"
                            placeholder='Address'
                            className='address-pg'
                        />


                        <ValidationError
                            prefix="Message"
                            field="message"
                            errors={state.errors}
                        />


                        <textarea rows={7}
                            id="message"
                            name="About Project"
                            placeholder='Project Description'
                            className='proj-desc-pg'
                        />
                        <ValidationError
                            prefix="Message"
                            field="message"
                            errors={state.errors}
                        />
                        <button type="submit" disabled={state.submitting}>
                            SUBMIT
                        </button>
                    </form>
                </div>
            </div>

            <img className="map" src={require('../assets/mapp.png')} />
            <Footer />
        </>

    )
}

export default ContactPg
