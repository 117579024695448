import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import '../styles/Footer.css';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const handleServiceClick = () => {
    navigate('/contact');
  };

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleAboutClick = () => {
    navigate('/about');
  };

  const handle3Click = () => {
    navigate('/services');
  };

  const handlePrivacy = () => {
    navigate('/policy');
  };

  const handleTerms = () => {
    navigate('/terms');
  };


  const [inView, setInView] = useState(false);
  const footerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  const buttonSpring = useSpring({
    transform: inView
      ? 'scale(1) rotate(0deg) translateY(0px)'
      : 'scale(1) rotate(0deg) translateY(0px)',
    config: { tension: 300, friction: 40 },
    from: { transform: 'scale(5) rotate(360deg) translateY(-50px)' },
  });

  return (
    <div className='footer-top-container'>
    <div className='footer-container' ref={footerRef}>
      <div className='footer-heading'>
        <div className='footer-up'>
          <h1>
            Would you like to work <br />
            with us? <span className='lets'>Let’s!</span> <span className='work-t'>work <br />
            together</span>
          </h1>
          <animated.button
            onClick={handleServiceClick}
            className='start'
            style={buttonSpring}
          >
            START A PROJECT?
          </animated.button>
        </div>
       
      </div>

      <div className='footer-center'>
        <div className='img-text'>
 
          <img src={require('../assets/location.png')} className='location-img' />
          <a 
        href='https://maps.app.goo.gl/o5SeiZFxhs7JGRQo8' 
        target='_blank' 
        rel='noopener noreferrer'
      >
          <p className='footer-loc-p'>Room No.8 Plot No B-9, Sahba Akhtar Rd,<br />Block 13D-2, Gulshan-e-Iqbal, Karachi</p>
          </a>

        </div>
        <div className="img-text">
           
              <img src={require('../assets/phone1.png')} alt="Phone" />
              <a href="tel:+923363361132">
              <p>+92 336 3361132</p>
            </a>
          </div>
        <div className='img-text'>
       
              <img src={require('../assets/mail.png')} alt="Email" />
              <a href="mailto:info@pixalogicsolutions.com">
              <p>info@pixalogicsolutions.com</p>
            </a>
        </div>
      </div>

      <div className='footer-right'>
        <p onClick={handleHomeClick}>Home</p>
        <p onClick={handleAboutClick}>About</p>
        <p onClick={handle3Click}>Services</p>
        <p onClick={handleTerms}>Terms Condition</p>
        <p onClick={handlePrivacy}>Privacy Policy</p>
        <p>FAQ</p>
      </div>
  
    </div>
        <div className='footer-down'>
        <p>@2024 Pixalogic Solutions - All Right Reserved</p>
      </div>
      </div>
  );
};

export default Footer;
