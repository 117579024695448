import React, { useEffect, useRef } from 'react'
import '../styles/About.css'
import { useNavigate } from 'react-router-dom';

const About = () => {

    const navigate = useNavigate();

    const handleServiceClick = () => {
      navigate('/about');
    };
  
    useEffect(() => {
      // Function to scroll to the top of the page
      const scrollToTop = () => {
        window.scrollTo(0, 0);
      };
  
      // Add event listener to scroll to top when the component unmounts
      return () => {
        scrollToTop();
      };
    }, []); // Run only once when the component mounts

    const boyImgRef = useRef(null);
    const rightCRef = useRef(null);
    const pumaRefs = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                    } else {
                        entry.target.classList.remove('animate');
                    }
                });
            },
            {
                threshold: 0.1, // Trigger the animation when 10% of the section is visible
            }
        );

        if (boyImgRef.current) {
            observer.observe(boyImgRef.current);
        }
        if (rightCRef.current) {
            observer.observe(rightCRef.current);
        }
        pumaRefs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            if (boyImgRef.current) {
                observer.unobserve(boyImgRef.current);
            }
            if (rightCRef.current) {
                observer.unobserve(rightCRef.current);
            }
            pumaRefs.current.forEach((ref) => {
                if (ref) {
                    observer.unobserve(ref);
                }
            });
        };
    }, []);

    return (
        <div className='container-about'>
            <div className='first-c'>
                <img src={require('../assets/pixels.jpg')} alt='about' className='about-img' />
                <div className='content-about'>
                    <div className='left-c fade-in' ref={boyImgRef}>
                        <img src={require('../assets/boy.png')} alt='about' className='left-about' />
                    </div>

                    <div className='right-c slide-in-right' ref={rightCRef}>
                        <h1>Little Bit About Us</h1>
                        <p>Need a brand design within minutes? We, at Pixalogic enable you to accomplish branding excellence
within few clicks. From crafting a brand’s logo to making business videos and entire brand strategies,
Pixalogic has got you covered. Free yourself from complications of marketing and unleash your creativity
with Pixalogic’s innovative features and services. </p>
                        <button onClick={handleServiceClick} className='read-more'>READ MORE</button>

                    </div>


                </div>
            </div>

            <div className='second-c'>
                <img src={require('../assets/L1.png')} alt='puma' className='puma zoom-in' ref={(el) => pumaRefs.current[0] = el} />
                <img src={require('../assets/L2.png')} alt='puma' className='puma zoom-in' ref={(el) => pumaRefs.current[1] = el} />
                <img src={require('../assets/L3.png')} alt='puma' className='puma zoom-in' ref={(el) => pumaRefs.current[2] = el} />
                <img src={require('../assets/L4.png')} alt='puma' className='puma zoom-in' ref={(el) => pumaRefs.current[3] = el} />
                <img src={require('../assets/L5.png')} alt='puma' className='puma zoom-in' ref={(el) => pumaRefs.current[4] = el} />
                <img src={require('../assets/L6.png')} alt='puma' className='puma zoom-in' ref={(el) => pumaRefs.current[5] = el} />

            </div>
        </div>
    )
}

export default About
