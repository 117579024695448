import React from 'react'
import Navbar2 from './Navbar2'
import '../styles/ServicesPg.css'
import Footer from './Footer';
const ServicesPg = () => {

    const services2 = [
        { img: require('../assets/horiz1.png'), img1: require('../assets/line1.png'), title: 'Logo Design', text: "Empower your brand to communicate creatively and visually. The only tool you need to enhance your brand recognition and identity. With tons of design ideas, dozens of design elements and numerous market competitive designs , Pixalogic is everything you need to shape amazing brand identity.", text2: "Pixalogic makes sure that not only your logo is visually appealing, but it also makes your brand standout in the crowded business world. Our design exports are renowned for timely delivery, excellent services and impressive designs." },
        { img: require('../assets/horiz2.png'), img1: require('../assets/line1.png'), title: 'Web Design', text: "Sit back, relax and let the professionals build a remarkable and mobile-friendly website that reflects your business values and objectives. At Pixalogic, our experts follow your directives to craft an online platform that ensures higher revenue and sales rates while guaranteeing better organic traffic generation and online presence.", text2: " From using technologically advanced tools and approaches to integrating flexible building platforms, Pixalogic provides you with best website building features and services." },
        { img: require('../assets/horiz3.png'), img1: require('../assets/line1.png'), title: 'Graphic Design', text: "Transform your brand with our expert graphic design services, where creativity meets precision. We create stunning visuals tailored to your vision, ensuring your message shines through brilliantly. Experience the power of creativity that captivates, converts, and sets you apart from the competition. From eye-catching logos to comprehensive marketing materials, we bring your unique ideas to life.", text2: "Our designs are crafted to resonate deeply with your audience and drive lasting impact. With our dedicated team, your brand will become unforgettable and truly exceptional. Let's make your brand speak volumes with designs that leave a lasting impression." },
        { img: require('../assets/horiz4.png'), img1: require('../assets/line1.png'), title: 'Digital Marketing', text: "Social media is a surefire way to generate more traffic. From increased Return on Investment to amplified conversions, the capabilities of social media are beyond your expectation, that’s why you need maintenance services. From page construction and insight reports to content creation and management, Pixalogic ensures well-established social media presence across the internet.", text2: "Offering you a wide array of services and features, make sure a consistent and strong social media presence and online visibility. Be it profile management, full-service content posting or audience building, Pixalogic makes your business standout in the crowded world of social media." },
        { img: require('../assets/horiz5.png'), img1: require('../assets/line1.png'), title: 'Mobile App Design', text: "Design and build phenomenal mobile applications for all types and sizes of smart devices and screens. At Pixalogic, we provide all-inclusive mobile app services, regardless of the type of platform and device. Your ultimate solution for creating exhilarating mobile experiences." , text2: "At Pixalogic, we follow a simple yet advanced development process to provide you with a brilliant masterpiece. Whether it’s a native, hybrid or cross platform app, we craft prolific solutions that are easy to use and even simpler to adopt to." },
        { img: require('../assets/horiz6.png'), img1: require('../assets/line1.png'), title: 'UI / UX Design', text: "Elevate your digital presence with our exceptional UI/UX services, designed to create intuitive and engaging user experiences. We prioritize user-centered design, ensuring every interaction is seamless and enjoyable. Our team crafts visually stunning interfaces that are not only aesthetically pleasing but also highly functional. Through in-depth research and testing, we understand your users' needs and tailor solutions accordingly." , text2: "We focus on optimizing usability and accessibility to enhance user satisfaction and retention. From wireframes to final prototypes, we deliver a cohesive and compelling user journey. Partner with us to transform your digital platforms into powerful tools that drive success and delight users." },
      ];



  return (
    <div className='full-services-pg'>
        <Navbar2 />
        <div className='container-services-pg'>
            <h1>Services</h1>

            {services2.map((service, index) => (
            <div className="service-pg-map" key={index}>
              <div className='img-con-pg'>
                <img src={service.img} alt={service.title} className='sp-img-edit'/>
                <h1>{service.title}</h1>

              </div>
              <div className="service-content-pg">
                <p>{service.text}</p>
                <p>{service.text2}</p>

              </div>
            </div>
          ))}

        </div>
      <Footer/>
    </div>
  )
}

export default ServicesPg
