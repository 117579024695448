import React, { useState, useEffect } from 'react';
import { useTransition, animated } from '@react-spring/web';
import useMeasure from 'react-use-measure';
import shuffle from 'lodash/shuffle';
import '../styles/WorkPg.css';
import { useNavigate } from 'react-router-dom';
import Navbar2 from './Navbar2';
import Footer from './Footer';

const content = [
 

  { id: 1, category: 'Logo Design', title: 'Logo 1', image: require('../assets/logo/01a.jpg'), imageM: require('../assets/logo/Logo-01.jpg') },
  { id: 2, category: 'Logo Design', title: 'Logo 2', image: require('../assets/logo/02a.jpg'), imageM: require('../assets/logo/Logo-02.jpg') },
  { id: 3, category: 'Logo Design', title: 'Logo 3', image: require('../assets/logo/03a.jpg'), imageM: require('../assets/logo/Logo-03.jpg') },
  { id: 4, category: 'Logo Design', title: 'Logo 4', image: require('../assets/logo/04a.jpg'), imageM: require('../assets/logo/Logo-04.jpg') },
  { id: 5, category: 'Logo Design', title: 'Logo 5', image: require('../assets/logo/05a.jpg'), imageM: require('../assets/logo/Logo-05.jpg') },
  { id: 6, category: 'Logo Design', title: 'Logo 6', image: require('../assets/logo/06a.jpg'), imageM: require('../assets/logo/Logo-06.jpg') },
  { id: 7, category: 'Logo Design', title: 'Logo 7', image: require('../assets/logo/07a.jpg'), imageM: require('../assets/logo/Logo-07.jpg') },
  { id: 8, category: 'Logo Design', title: 'Logo 8', image: require('../assets/logo/08a.jpg'), imageM: require('../assets/logo/Logo-08.jpg') },
  { id: 9, category: 'Logo Design', title: 'Logo 9', image: require('../assets/logo/09a.jpg'), imageM: require('../assets/logo/Logo-09.jpg') },
  { id: 10, category: 'Logo Design', title: 'Logo 10', image: require('../assets/logo/10a.jpg'), imageM: require('../assets/logo/Logo-10.jpg') },
  { id: 11, category: 'Logo Design', title: 'Logo 11', image: require('../assets/logo/11a.jpg'), imageM: require('../assets/logo/Logo-11.jpg') },
  { id: 12, category: 'Logo Design', title: 'Logo 12', image: require('../assets/logo/12a.jpg'), imageM: require('../assets/logo/Logo-12.jpg') },
  { id: 13, category: 'Logo Design', title: 'Logo 13', image: require('../assets/logo/13a.jpg'), imageM: require('../assets/logo/Logo-13.jpg') },
  { id: 14, category: 'Logo Design', title: 'Logo 14', image: require('../assets/logo/14a.jpg'), imageM: require('../assets/logo/Logo-14.jpg') },
  { id: 15, category: 'Logo Design', title: 'Logo 15', image: require('../assets/logo/15a.jpg'), imageM: require('../assets/logo/Logo-15.jpg') },





  { id: 7, category: 'Mobile App', title: 'Mobile App 1', image: require('../assets/ma/01a.png'), imageM: require('../assets/ma/01nn.png') },
  { id: 8, category: 'Mobile App', title: 'Mobile App 2', image: require('../assets/ma/02a.png'), imageM: require('../assets/ma/02o.jpg') },
  { id: 9, category: 'Mobile App', title: 'Mobile App 3', image: require('../assets/ma/03a.png') , imageM: require('../assets/ma/03.png')},
  { id: 10, category: 'Mobile App', title: 'Mobile App 4', image: require('../assets/ma/04a.png'), imageM: require('../assets/ma/04.png') },
  { id: 11, category: 'Mobile App', title: 'Mobile App 5', image: require('../assets/ma/07a.png'), imageM: require('../assets/ma/07n.png') },
  { id: 12, category: 'Mobile App', title: 'Mobile App 6', image: require('../assets/ma/06a.png') , imageM: require('../assets/ma/06n.jpg')},


  { id: 22, category: 'Mobile App', title: 'Mobile App 6', image: require('../assets/ma/08a.png') , imageM: require('../assets/ma/08n.png')},
  { id: 23, category: 'Mobile App', title: 'Mobile App 6', image: require('../assets/ma/09a.png') , imageM: require('../assets/ma/09n.png')},
  { id: 24, category: 'Mobile App', title: 'Mobile App 6', image: require('../assets/ma/10a.png') , imageM: require('../assets/ma/10.png')},


  { id: 25, category: 'Print Media', title: 'Print Media 1', image: require('../assets/pm/01a.jpg') ,imageM: require('../assets/pm/01.jpg') },
  { id: 26, category: 'Print Media', title: 'Print Media 2', image: require('../assets/pm/02a.jpg'),imageM: require('../assets/pm/02.jpg') },
  { id: 27, category: 'Print Media', title: 'Print Media 3', image: require('../assets/pm/03a.jpg'),imageM: require('../assets/pm/03.jpg') },
  { id: 28, category: 'Print Media', title: 'Print Media 4', image: require('../assets/pm/04a.jpg') ,imageM: require('../assets/pm/04.jpg')},
  { id: 29, category: 'Print Media', title: 'Print Media 5', image: require('../assets/pm/05a.jpg') ,imageM: require('../assets/pm/05.jpg')},
  { id: 30, category: 'Print Media', title: 'Print Media 6', image: require('../assets/pm/06a.jpg') ,imageM: require('../assets/pm/06.jpg')},
  { id: 31, category: 'Print Media', title: 'Print Media 6', image: require('../assets/pm/07a.jpg') ,imageM: require('../assets/pm/07.jpg')},
  { id: 32, category: 'Print Media', title: 'Print Media 6', image: require('../assets/pm/08a.jpg') ,imageM: require('../assets/pm/08.jpg')},
  { id: 33, category: 'Print Media', title: 'Print Media 6', image: require('../assets/pm/09a.jpg') ,imageM: require('../assets/pm/09.jpg')},


  { id: 34, category: 'Web Design', title: 'Web Design 1', image: require('../assets/wd/01.jpg'),imageM: require('../assets/wd/1a.jpg') },
  { id: 35, category: 'Web Design', title: 'Web Design 2', image: require('../assets/wd/02.jpg'),imageM: require('../assets/wd/2a.jpg') },
  { id: 36, category: 'Web Design', title: 'Web Design 3', image: require('../assets/wd/03.jpg') ,imageM: require('../assets/wd/3a.jpg')},
  { id: 37, category: 'Web Design', title: 'Web Design 4', image: require('../assets/wd/04.jpg') ,imageM: require('../assets/wd/4a.jpg')},
  { id: 38, category: 'Web Design', title: 'Web Design 5', image: require('../assets/wd/05.jpg') ,imageM: require('../assets/wd/5a.jpg')},
  { id: 39, category: 'Web Design', title: 'Web Design 6', image: require('../assets/wd/06.jpg') ,imageM: require('../assets/wd/6a.jpg')},
  { id: 40, category: 'Web Design', title: 'Web Design 6', image: require('../assets/wd/07.jpg') ,imageM: require('../assets/wd/7a.jpg')},
  { id: 41, category: 'Web Design', title: 'Web Design 6', image: require('../assets/wd/08.jpg') ,imageM: require('../assets/wd/8a.jpg')},
  { id: 42, category: 'Web Design', title: 'Web Design 6', image: require('../assets/wd/09.jpg') ,imageM: require('../assets/wd/9a.jpg')},

];

const ImageModal = ({ imageUrl, onClose }) => {
  const handleBackgroundClick = (e) => {
    // Close the modal if the user clicks outside the image
    if (e.target.className === 'modal') {
      onClose();
    }}
  return (
  
    <div className="modal"  >
    <span className="close" onClick={onClose}>&times;</span>
    <div className="modal-content" onClick={handleBackgroundClick}>
      <img src={imageUrl} alt="Modal" />
    </div>
  </div>
  );
};


const WorkPg = () => {

  

  const navigate = useNavigate();
  const [modalImageUrl, setModalImageUrl] = useState(null);

  // const handleServiceClick = () => {
  //   navigate('/work');
  // };

  const handleImageClick = (image, imageM) => {
    setModalImageUrl(imageM || image);
  };

  const handleCloseModal = () => {
    setModalImageUrl(null);
  };

  useEffect(() => {
    // Function to scroll to the top of the page
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    // Add event listener to scroll to top when the component unmounts
    return () => {
      scrollToTop();
    };
  }, []); // Run only once when the component mounts

  const [activeCategory, setActiveCategory] = useState('Logo Design');
  const [items, setItems] = useState(content);
  const [ref, { width }] = useMeasure();

  const handleFilterClick = (category) => {
    setActiveCategory(category);
  };

  const filteredContent = activeCategory === 'All'
    ? items
    : items.filter(item => item.category === activeCategory);

  return (
    <>
    <Navbar2 />
    <div className='work-container-pg'>
      <h1 className='work-title-pg'>Work</h1>

      <div className="work-links">
        <a 
          onClick={(e) => { e.preventDefault(); handleFilterClick('All') }} 
          href="#" 
          className={activeCategory === 'All' ? 'active' : ''}
        >
          All
        </a>
        <a 
          onClick={(e) => { e.preventDefault(); handleFilterClick('Logo Design') }} 
          href="#" 
          className={activeCategory === 'Logo Design' ? 'active' : ''}
        >
          Logo Design
        </a>
        <a 
          onClick={(e) => { e.preventDefault(); handleFilterClick('Mobile App') }} 
          href="#" 
          className={activeCategory === 'Mobile App' ? 'active' : ''}
        >
          Mobile App
        </a>
        <a 
          onClick={(e) => { e.preventDefault(); handleFilterClick('Print Media') }} 
          href="#" 
          className={activeCategory === 'Print Media' ? 'active' : ''}
        >
          Print Media
        </a>
        <a 
          onClick={(e) => { e.preventDefault(); handleFilterClick('Web Design') }} 
          href="#" 
          className={activeCategory === 'Web Design' ? 'active' : ''}
        >
          Web Design
        </a>
      </div>

      <div ref={ref} className="images-grid">
        {filteredContent.map(item => (
          <div key={item.id} className="content-item" onClick={() => handleImageClick(item.image, item.imageM)}>
            <img src={item.image} alt={item.title} />
          </div>
        ))}
      </div>

      {modalImageUrl && <ImageModal imageUrl={modalImageUrl} onClose={handleCloseModal} />}

      {/* {activeCategory !== 'All' && (
        <button onClick={handleServiceClick} className='work-btn'>VIEW ALL</button>
      )} */}
    </div>
    <Footer  className="footer-margin-top" />
    </>

  );
};

export default WorkPg;
