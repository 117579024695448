import React from 'react';
import Navbar2 from './Navbar2';
import '../styles/AboutPg.css';
import Footer from '../pages/Footer.js';


const AboutPg = () => {
  return (
    <div className='about-page-wrapper'>
      <Navbar2 />
      <div className='container-aboutpg'>
        <h1>About</h1>

        <div className='wrap-aboutpg'>
          <div className='content-aboutpg'>
            <div className='content-aboutpg-img'>
              <img src={require('../assets/aboutpg1.png')} alt="About Us" />
            </div>
            <div className='content-right-aboutpg'>
              <h2 className='who'>Our Mission</h2>
              <p>Our mission is to transform ideas into impactful visual experiences that resonate, inspire,
                and drive success. We are dedicated to pushing the boundaries of creativity, innovation,
                and design excellence to empower our clients with the tools they need to thrive in a
                dynamic and ever-evolving marketplace.</p>
              <p>Our vision is to lead the design industry with integrity and passion. We believe in the power of design to tell compelling stories, build authentic connections,
                and solve complex challenges. With a relentless commitment to quality, collaboration,
                and client satisfaction, we aim to be the creative force behind brands and businesses
                that dare to dream big.</p>
            </div>
          </div>

          <div className='content-aboutpg2'>
            <div className='content-aboutpg-img2'>
              <h2>Our Vision</h2>
              <p>Our primary goal is to be a leading force in the world of design, setting new standards for creativity, innovation, and
                client-centric solutions. Our overarching objectives are deeply rooted in our commitment to excellence, and they
                guide our agency's growth, impact, and reputation.</p>

               <p> Our overarching objectives are deeply rooted in our unwavering commitment to excellence. We believe that every project is an opportunity to showcase our dedication to quality and our passion for design.</p>

              <p>  Ultimately, our vision is to inspire and lead, creating designs that make a difference and leave a lasting impression.</p>

            </div>
            <div className='content-right-aboutpg2'>
              <img src={require('../assets/aboutpg2.png')} alt="About Us" />
            </div>
          </div>
        </div>

        <div className='blue-sp'>
          <img src={require('../assets/pixels2.jpg')} className='service-bg-sp' alt="background" />

          <div className='blue-content-sp'>
            <h1>A forward-thinking team of <br />
              designers, copywriters, and developers.</h1>
            <div className='counters-sp'>

              <div className='counter-title-sp'>
                <img src={require('../assets/sp-1.png')} />
                <p>Trusted Company</p>
              </div>

              <div className='counter-title-sp'>
                <img src={require('../assets/sp-2.png')} />
                <p>Award Winning</p>
              </div>

              <div className='counter-title-sp'>
                <img src={require('../assets/sp-3.png')} />
                <p>Professional work</p>
              </div>

              <div className='counter-title-sp'>
                <img src={require('../assets/sp-4.png')} />
                <p>Help any time</p>
              </div>

            </div>
          </div>
        </div>


        <div className='white-aboutpg'>

          <div className='left-aboutpg'>

            <img src={require('../assets/orange-ap.png')} />
            <h1>We Aim To Bring <br />
              Businesses Into <br />
              The Digital World.</h1>

          </div>

          <div className='right-aboutpg'>

            <div className='right-aboutpg-top'>
              <h1>Successfully finished <br />
                projects with creativity.</h1>
              <p>We take pride in our successfully finished projects, each a testament to our creativity and dedication. Our team ensures that every design is not only visually stunning but also strategically aligned with your goals.</p>

            </div>

            <div className='right-aboutpg-top'>
              <h1>Work together for better <br />
                branding solutions.</h1>
              <p>Join us to collaborate on innovative branding solutions. Together, we’ll craft a unique identity that elevates your brand. Let's create impactful designs that resonate and drive success.</p>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutPg;
