import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Work from './pages/Work';
import Testinomials from './pages/Testinomials';
import Footer from './pages/Footer';
import ServicesPg from './pages/ServicesPg';
import WorkPg from './pages/WorkPg';
import ContactPg from './pages/ContactPg';
import AboutPg from './pages/AboutPg';
import ScrollToTop from './components/ScrollToTop'; // Adjust the path accordingly

import './App.css'
import Policy from './pages/Policy';
import Terms from './pages/Terms';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={
          <div>
            <section id="home-section">
              <Home />
            </section>

            <section id="about-section">
              <About />
            </section>

            <section id="service-section">
              <Services />
            </section>

            <section id="contact-section">
              <Contact />
            </section>

            <section id="work-section">
              <Work />
            </section>

            <section id="testinomials-section">
              <Testinomials />
            </section>
            
            <section id="footer-section">
              <Footer />
            </section>
          </div>
        } />
        <Route path="/services" element={<ServicesPg />} />
        <Route path='/work' element={<WorkPg />} />
        <Route path='/contact' element={<ContactPg />} />
        <Route path='/about' element={<AboutPg />} />
        <Route path='/policy' element={<Policy/>} />
        <Route path='/terms' element={<Terms />} />

      </Routes>
    </Router>
  );
}

export default App;
